<script>
import DetailFormGroup from '@/components/DetailFormGroup'
import { postAction, putAction } from '@/command/netTool'

export default {
  name: 'exchangeDetail',
  data() {
    return {
      categoryArr: [
        {
          name: '实时',
          value: '1',
        },
        {
          name: '每月',
          value: '2',
        },
      ],
      scjyArr: [
        {
          name: '手动',
          value: '1',
        },
        {
          name: '自动',
          value: '2',
        },
      ],
      deviceArr: [
        {
          name: '成功',
          value: '1',
        },
        {
          name: '失败',
          value: '2',
        },
      ],
    }
  },
  computed: {},
  mounted() {},
  methods: {
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '系统名称',
            type: 'input',
            key: 'mainName',
            cols: 12,
            props: {
              maxLength: 20,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: 'KEY',
            type: 'input',
            cols: 12,
            key: 'creditCode',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '密钥',
            type: 'input',
            cols: 12,
            key: 'personName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '白名单IP',
            type: 'input',
            cols: 12,
            key: 'idCard',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '交换时间',
            type: 'datePicker',
            cols: 12,
            key: 'produce',
            props: {
              showTime: true,
              format: 'YYYY-MM-DD',
              valueFormat: 'YYYY-MM-DD HH:mm:ss',
              placeholder: '请选择创建日期',
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '交换频率',
            type: 'select',
            cols: 12,
            key: 'mainBodyType',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
            typeData: this.categoryArr,
          },

          {
            name: '切换方式',
            type: 'select',
            cols: 12,
            key: 'producerType',
            typeData: this.scjyArr,
          },
          {
            name: '切换方式',
            type: 'select',
            cols: 12,
            key: 'producerType',
            typeData: this.deviceArr,
          },
          // {
          //   name: '交换状态',
          //   type: 'switch',
          //   cols: 12,
          //   key: 'producer',
          //   typeData: this.deviceArr,
          // },
        ],
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          sumbit: true,
          onClick: (data) => {
            if (data.id) {
              putAction('/api/', {
                ...data,
              }).then((res) => {
                this.$router.push('/iot')
              })
            } else {
              console.log(data)
              postAction('/api/', {
                ...data,
              }).then((res) => {
                this.$router.push('/iot')
              })
            }
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/iot'),
        },
      ]
      return {
        left,
      }
    },
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} data={[this.getForm1()]} />
  },
}
</script>
